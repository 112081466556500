import templateUrl from './huitem.view.html'

/**
 * Item detail Report route
 *
 * @namespace routes.ReportItemView
 * @type {TmrRoute}
 */
export const route = {
  name: 'report.huitems.view',
  label: 'REPORT.HUITEMS.VIEW.TITLE',
  component: 'tmrReportHUItemView',
  accessControl: ['Resource.list', 'Resource.HUItem.list'],
  abstract: false,
  paging: true,
  resolve: {
    movementTypes: /*@ngInject*/ tmrMovementTypes => tmrMovementTypes,
    productImageEnabled: /*@ngInject*/ appConfiguration =>
      appConfiguration['product.image.enabled'],
    transfers: /*@ngInject*/ ($transition$, Transfer, TmrPaging, resource) =>
      Transfer.pagedQuery(
        TmrPaging.resolveParams($transition$, { 'item.epc': resource.epc })
      ),
    transfersCount: /*@ngInject*/ (Transfer, resource) =>
      Transfer.count({ 'item.epc': resource.epc }),
    children: /*@ngInject*/ ($transition$, HUItem, TmrPaging, resource) =>
      HUItem.pagedQuery(
        TmrPaging.resolveParams($transition$, { 'parentId': resource.id })
      ),
    childrenCount: /*@ngInject*/ (HUItem, resource) =>
    HUItem.count({ 'parentId': resource.id }),
  },
}

/**
 * Component for {@link routes.tmrReportHUItemView Items Report route}
 *
 * @type {ngComponent}
 */
 export const tmrReportHUItemView = {
  ngType: 'component',
  templateUrl,
  bindings: {
    resource: '<',
    movementTypes: '<',
    transfers: '<',
    transfersCount: '<',
    children: '<',
    childrenCount: '<',
    currentPage: '<',
    pageSize: '<',
    productImageEnabled: '<',
  },
}
