import templateUrl from './tests.html'
import { resourceListBindings } from 'tmr/components/resourceList/resourceListHelper'
/**
 * Tests Report route
 *
 * @namespace routes.ReportTests
 * @type {TmrRoute}
 */
export const route = {
  name: 'report.test.list',
  label: 'REPORT.TEST.TITLE',
  url: '/tests',
  component: 'tmrReportTest',
  weight: 0,
  resourceType: 'Test',
  resourceList: {
    searchParameters: ['executed', 'huCode']
  },
  accessControl: ['Resource.list', 'Resource.Test.list'],
}

/**
 * Component for {@link routes.tmrReportTest Tests Report route}
 *
 * @type {ngComponent}
 */
export const tmrReportTest = {
  ngType: 'component',
  templateUrl,
  bindings: resourceListBindings(),
}
