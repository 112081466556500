import { register } from 'tmr/tmr/ngModuleRegistry'

// Themeing.
import './theme/theme.scss'

// Constants.
import * as apiPrefix from './constants/apiPrefix'
import * as appName from './constants/appName'
import * as defaultApiEndpoints from './constants/defaultApiEndpoints'
import * as movementTypes from './constants/movementTypes'
import * as defaultPagingParams from './constants/defaultPagingParams'

import * as automaticTTZDestinationResource from './resources/automaticTTZDestination/automaticTTZDestination'
import * as propellantZoneResource from './resources/zoneWithPropellant/zoneWithPropellant'
import * as propellantPlaceResource from './resources/placeWithPropellant/placeWithPropellant'
import * as huitemResource from './resources/huitem/huitem'
import * as genericTankResource from './resources/genericTank/genericTank'
import * as TestResource from './resources/test/test'

import * as automaticTTZDestinationsRoute from './routes/admin/automaticTTZDestinations/automaticTTZDestinations'
import * as automaticTTZDestinationsRouteForm from './routes/admin/automaticTTZDestinations/form'
import * as propellantZoneRoute from './routes/report/propellant/propellantZone'
import * as propellantPlaceRoute from './routes/report/propellant/propellantPlace'
import './routes/admin/zones/zones.html'
import './routes/admin/places/places.html'
import './routes/admin/places/form.html'
import * as zonesRouteForm from './routes/admin/zones/form'
import * as huitemsRoute from './routes/report/huitems/huitems'
import * as huitemsViewRoute from './routes/report/huitems/huitem.view'
import * as genericTanksRoute from './routes/admin/genericTanks/genericTanks'
import * as genericTanksRouteForm from './routes/admin/genericTanks/form'
import * as TestRoute from './routes/report/test/tests'
import * as transferRoute from './routes/report/transfers/transfers'
import * as movementHtml from './routes/report/movements/movements.html'
import * as movementViewHtml from './routes/report/movements/movement.view.html'
import * as movementEncodingViewHtml from './routes/report/movements/encoding/movementEncoding.view.html'
import * as movementEncodingHtml from './routes/report/movements/encoding/movementsEncoding.html'
import * as movementInboundViewHtml from './routes/report/movements/movementInbound.view.html'
import * as movementInventoryConfirmationViewHtml from './routes/report/movements/movementInventoryConfirmation.view.html'
import * as movementOutboundViewHtml from './routes/report/movements/movementOutbound.view.html'
import * as movementReturnViewHtml from './routes/report/movements/movementReturn.view.html'
import * as movementSaleViewHtml from './routes/report/movements/movementSale.view.html'
import * as movementShippingInboundViewHtml from './routes/report/movements/movementShippingInbound.view.html'
import * as movementShippingOutboundViewHtml from './routes/report/movements/movementShippingOutbound.view.html'
import * as movementTransferToZoneViewHtml from './routes/report/movements/movementTransferToZone.view.html'

import * as scannerResource from './resources/scanner/scanner'
import * as scannerHtml from './routes/devices/scanners/scanners.html'
import * as scannerRouteForm from './routes/devices/scanners/form.html'
import * as scannerViewHtml from './routes/devices/scanners/scanner.view.html'

import * as propellantNavBar from './components/navBar/propellantNavBar/propellantNavBar.js'

import * as forgotPassword from "./routes/forgotPassword/forgotPassword";
import * as login from './routes/login/login.controller.js'
import './components/logoSidebar/logoSidebar.html'
import * as aboutModal from './components/modals/aboutModal/aboutModal.html'

// See src/app/examples/index.js for examples

// Add everything to the module registry.
register(
    'tmr.BRAND',
    apiPrefix,
    appName,
    defaultApiEndpoints,
    movementTypes,
    automaticTTZDestinationResource,
    automaticTTZDestinationsRoute,
    automaticTTZDestinationsRouteForm,
    propellantZoneResource,
    propellantPlaceResource,
    scannerResource,
    huitemResource,
    genericTankResource,
    propellantZoneRoute,
    propellantPlaceRoute,
    propellantNavBar,
    zonesRouteForm,
    huitemsRoute,
    huitemsViewRoute,
    genericTanksRoute,
    genericTanksRouteForm,
    login,
    forgotPassword,
    TestResource,
    TestRoute,
    scannerRouteForm,
    movementHtml,
    movementViewHtml,
    movementEncodingViewHtml,
    movementInboundViewHtml,
    movementInventoryConfirmationViewHtml,
    movementOutboundViewHtml,
    movementReturnViewHtml,
    movementSaleViewHtml,
    movementShippingInboundViewHtml,
    movementShippingOutboundViewHtml,
    movementTransferToZoneViewHtml,
    scannerHtml,
    scannerViewHtml,
    movementEncodingHtml,
    defaultPagingParams,
    transferRoute,
    aboutModal,
    )
