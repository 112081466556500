import { tmrResourceFormController } from 'tmr/controllers/resourceForm/resourceForm'
import templateUrl from './form.html'

/**
 * Zone add/edit form component.
 *
 * @type {ngComponent}
 */
export const tmrAdminGenericTankForm = {
  ngType: 'component',
  templateUrl,
  controller: 'tmrAdminGenericTankFormController',
  bindings: {
    resource: '<',
    toast: '<',
  },
}

/**
 * Controller of component {@link tmrAdminZoneForm}.
 *
 * @type {ngController}
 */
export class tmrAdminGenericTankFormController extends tmrResourceFormController {
  constructor(
    $scope,
    $state,
    $stateParams,
    TmrInvalidFormError,
    TmrToast,
    Zone,
    $translate
  ) {
    'ngInject'
    super($scope, $state, $stateParams, TmrInvalidFormError, TmrToast)
    Object.assign(this, { Zone }, {$translate})
  }

  getZones(value) {
    return this.Zone.search({ value }).$promise
  }

  onSubmit(){
    if(this.resource.propellant<0){
      const err = new Error(this.$translate.instant('ADMIN.ERRORS.PORPELLANTNEGETIVE'))
      this.TmrToast.exception(err, !(err instanceof this.TmrInvalidFormError))
    }else
    super.onSubmit()
  }
}
