/**
 * Resource that represents an HUItem.
 *
 * @name HUItem
 * @class
 * @augments TmrResource
 */
function HUItemFactory(TmrResource, Product, Zone) {
  'ngInject'
  let referencedProperties = [
    // huitem.product property will be instantiated as a Product resource
    { property: 'product', resource: Product },
    // huitem.zone property will be instantiated as a Zone resource
    { property: 'zone', resource: Zone },
  ]

  let HUItem = TmrResource('HUItem', {}, referencedProperties)

  HUItem.getByCode = getByCode
  HUItem.prototype.$getProduct = $getProduct

  /**
   * Override default label
   *
   * @method HUItem#getLabel
   */
  HUItem.prototype.$getLabel = function () {
    return this.$identifiers[0].code
  }

  /**
   * Backwards-compatible identifiers property
   */
  Object.defineProperty(HUItem.prototype, '$identifiers', {
    get: function () {
      if (!Array.isArray(this.itemIdentifiers))
        this.itemIdentifiers = [{ identifierType: 'UHFTag', code: this.epc }]
      if(this.itemIdentifiers.length === 0)
        this.itemIdentifiers = [{ identifierType: 'UHFTag', code: null }]
      return this.itemIdentifiers
    },
  })

  return HUItem

  /**
   * @method  HUItem#getByCode
   */
  function getByCode(code) {
    if (!code) {
      throw new Error(
        'HUItem.getByCode(code) action requires a code (upc) parameter'
      )
    }
    return this.query({ code, limit: 1 }).then(result => result[0])
  }

  /**
   * Loads the product linked to this huitem.
   *
   * @method  HUItem#$getProduct
   */
  function $getProduct() {
    if (this.$product === undefined) {
      this.$product = this.upc ? Product.getByUpc(this.upc) : null
    }
    return this.$product
  }
}

export const factory = ['HUItem', HUItemFactory]
