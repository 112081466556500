import { route as coreRoute } from 'tmr/routes/report/items/items'
import templateUrl from './genericTanks.html'
import { resourceListBindings } from 'tmr/components/resourceList/resourceListHelper'
/**
 * Items Report route
 *
 * @namespace routes.GenericTanks
 * @type {TmrRoute}
 */
export const route = {
  name: 'admin.genericTanks.list',
  label: 'ADMIN.GENERIC_TANKS.TITLE',
  url: '/genericTanks',
  component: 'tmrAdminGenericTanks',
  weight: 0,
  resourceType: 'GenericTank',
  resourceList: {
    searchParameters: ['genericTank', 'hasPropellant', 'quantity'].concat(
      coreRoute.resourceList.searchParameters),
  },
  resolve: {
    productImageEnabled: /*@ngInject*/ appConfiguration =>
      appConfiguration['product.image.enabled'],
  },
  accessControl: ['Resource.list', 'Resource.GenericTank.list'],
}

/**
 * Component for {@link routes.tmrAdminGenericTanks HUItems Report route}
 *
 * @type {ngComponent}
 */
 export const tmrAdminGenericTanks = {
  ngType: 'component',
  templateUrl,
  bindings: resourceListBindings({
    productImageEnabled: '<',
  }),
}