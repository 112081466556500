import logo from '../../../images/new-logo-big.png'
import { tmrLoginController } from 'tmr/routes/login/login'


/**
 * Controller of component {@link tmrLoginController}
 *
 * @type {ngController}
 */
export class tmrLoginControllerOverride extends tmrLoginController {
  cookieString;

  constructor(
    $state,
    TmrApiAuth,
    TmrInvalidCredentialsError,
    TmrConnectionTimeoutError
  ) {
    super($state, TmrApiAuth, TmrInvalidCredentialsError, TmrConnectionTimeoutError);

    Object.assign(this, {
      logo
    })

    this.getTokenFromCookie()
  }

  getTokenFromCookie() {
    this.cookieString = this.getCookie('tmr.chiesi.authToken');
    !!this.cookieString ? localStorage.setItem('tmr.chiesi-tmr-api.authToken', this.cookieString) : undefined;
    this.cookieString = this.cookieString.replace(/\"/g, "");
    this.TmrApiAuth.updateLoggedUser(this.cookieString);
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}

const tmrLoginDirective = /*@ngInject*/ $delegate => {
  $delegate[0].controller = 'tmrLoginControllerOverride'
  return $delegate
}

export const decorator = [
  'tmrLoginDirective',
  tmrLoginDirective,
]
