/**
 * Resource that represents a Zone.
 *
 * @name Zone
 * @class
 * @augments TmrResource
 */
function ZoneFactory(TmrResource, tmrZoneTypes, Place) {
  'ngInject'
  let referencedProperties = [
    // zone.place property will be instantiated as a Place resource
    { property: 'place', resource: Place },
  ]

  let ZoneWithPropellant = TmrResource('ZoneWithPropellant', {}, referencedProperties)

  ZoneWithPropellant.getTypeDescription = getTypeDescription
  ZoneWithPropellant.prototype.$getTypeDescription = getTypeDescription

  return ZoneWithPropellant

  /**
   * Returns the Zone type description.
   *
   * @method Zone#getTypeDescription
   */
  function getTypeDescription() {
    return tmrZoneTypes[this.zoneType]
  }
}

export const factory = ['ZoneWithPropellant', ZoneFactory]
