import { resourceListBindings } from 'tmr/components/resourceList/resourceListHelper'
import templateUrl from './propellantZone.html'

/**
 * Report Propellant in Zones route
 *
 * @namespace routes.ReportPropellantZone
 * @type {TmrRoute}
 */
export const route = {
  name: 'report.propellantZone.list',
  label: 'REPORT.PROPELLANT.TITLE',
  url: '/propellant/zone',
  component: 'tmrReportPropellantZone',
  weight: 3,
  resourceType: 'ZoneWithPropellant',
  accessControl: ['Resource.list', 'Resource.Propellant.list'],
  resourceList: {
    searchParameters: ['code', 'description', 'place.id', 'zoneType'],
  },
  resolve: {
    zoneTypes: /*@ngInject*/ tmrZoneTypes => tmrZoneTypes,
  },
}

/**
 * Component for {@link routes.tmrReportZones Report Zones route}
 *
 * @type {ngComponent}
 */
export const tmrReportPropellantZone = {
  ngType: 'component',
  templateUrl,
  bindings: resourceListBindings({
    zoneTypes: '<',
  }),
}
