import templateUrl from './propellantNavBar.html'

/**
 * Propellants NavBar component to switch between productionOrder types report.
 *
 * @example
 *   <tmr-propellant-nav-bar></tmr-propellant-nav-bar>
 *
 * @type {ngComponent}
 */
export const tmrPropellantNavBar = {
  ngType: 'component',
  templateUrl,
}
