import { route as coreRoute } from 'tmr/routes/report/items/items'
import templateUrl from './huitems.html'
import { resourceListBindings } from 'tmr/components/resourceList/resourceListHelper'
/**
 * Items Report route
 *
 * @namespace routes.ReportItems
 * @type {TmrRoute}
 */
export const route = {
  name: 'report.huitems.list',
  label: 'REPORT.HUITEMS.TITLE',
  url: '/huitems',
  component: 'tmrReportHUItems',
  weight: 0,
  resourceType: 'HUItem',
  resourceList: {
    searchParameters: ['genericTank', 'hasPropellant', 'quantity','huCode', 'consignment', 'material'].concat(
      coreRoute.resourceList.searchParameters),
  },
  resolve: {
    productImageEnabled: /*@ngInject*/ appConfiguration =>
      appConfiguration['product.image.enabled'],
  },
  accessControl: ['Resource.list', 'Resource.HUItem.list'],
}

/**
 * Component for {@link routes.tmrReportHUItems HUItems Report route}
 *
 * @type {ngComponent}
 */
 export const tmrReportHUItems = {
  ngType: 'component',
  templateUrl,
  bindings: resourceListBindings({
    productImageEnabled: '<',
  }),
}
