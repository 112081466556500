/**
 * Scanner resource
 *
 * @name Scanner
 * @class
 * @augments TmrResource
 */
export const factory = [
  'Scanner',
  /*@ngInject*/ (
    TmrApi,
    TmrResource,
    Antenna,
    DeviceController,
    Zone,
    User
  ) => {
    let actions = {
      start: {
        method: 'POST',
        url: TmrApi.endpoint('Scanner.start'),
      },
      stop: {
        method: 'POST',
        url: TmrApi.endpoint('Scanner.stop'),
      },
    }
    let referencedProperties = [
      // scanner.rfidAntennas property will be instantiated as a Antenna resource
      { property: 'rfidAntennas', resource: Antenna },
      // scanner.deviceController property will be instantiated as a DeviceController resource
      { property: 'deviceController', resource: DeviceController },
      // scanner.targetZone property will be instantiated as a Zone resource
      { property: 'targetZone', resource: Zone },
      // scanner.scannerUser property will be instantiated as a User resource
      { property: 'scannerUser', resource: User },
    ]
    let defaultValues = () => ({
      rfidAntennas: [],
    })

    let Scanner = TmrResource(
      'Scanner',
      actions,
      referencedProperties,
      defaultValues
    )

    // Replace actions that find attributes to simplify usage.
    Object.assign(Scanner, { status })
    Object.assign(Scanner.prototype, { $getOperation, $setOperation, $status })

    /**
     * Gets the status of a scanner
     *
     * @method Scanner#status
     * @returns {string}
     */
    function status(id) {
      if (id === undefined)
        throw new Error('Scanner.status(id) action requires a scanner id')
      return TmrApi.get('Scanner.status', { id }).then(
        response => response.data.status
      )
    }

    /**
     * Gets the status of this scanner
     */
    function $status() {
      return status(this.id)
    }

    /**
     * Gets the active operation on this scanner
     */
    function $getOperation() {
      return this.transferToZoneEnabled ? 'transferToZone' : ''
    }

    /**
     * Sets the active operation on this scanner
     */
    function $setOperation(operation) {
      this.transferToZoneEnabled = operation == 'transferToZone'
    }

    return Scanner
  },
]
