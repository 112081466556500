import { resourceListBindings } from 'tmr/components/resourceList/resourceListHelper'
import templateUrl from './automaticTTZDestinations.html'

/**
 * Administer AutomaticTTZDestination
 *
 * @namespace routes.AdminAutomaticTTZDestination
 * @type {TmrRoute}
 */
export const route = {
  name: 'admin.automaticTTZDestinations.list',
  label: 'ADMIN.AUTOMATICTTZDESTINATIONS.TITLE',
  url: '/automaticTTZDestinations',
  component: 'tmrAdminAutomaticTTZDestinations',
  weight: 0,
  resourceType: 'AutomaticTTZDestination',
  accessControl: ['Resource.list', 'Resource.AutomaticTTZDestination.list'],
  resourceList: {
    searchParameters: ['scanner.id', 'itemZone.id', 'destinationZone.id'],
  },
}

/**
 * Component for {@link routes.tmrAdminAutomaticTTZDestinations Administer AutomaticTTZDestinations route}
 *
 * @type {ngComponent}
 */
 export const tmrAdminAutomaticTTZDestinations = {
  ngType: 'component',
  templateUrl,
  bindings: resourceListBindings(),
}
