/**
 * Resource that represents a AutomaticTTZDestination.
 *
 * @name AutomaticTTZDestination
 * @class
 * @augments TmrResource
 */
 function AutomaticTTZDestinationFactory(TmrResource, Zone, Scanner) {
  'ngInject'
  let referencedProperties = [
    // automaticTTZDestination.scanner property will be instantiated as a Scanner resource
    { property: 'scanner', resource: Scanner },
    // automaticTTZDestination.itemZone property will be instantiated as a Zone resource
    { property: 'itemZone', resource: Zone },
    // automaticTTZDestination.destinationZone property will be instantiated as a Zone resource
    { property: 'destinationZone', resource: Zone },
  ]

  let AutomaticTTZDestination = TmrResource('AutomaticTTZDestination', {}, referencedProperties)

  return AutomaticTTZDestination

}

export const factory = ['AutomaticTTZDestination', AutomaticTTZDestinationFactory]
