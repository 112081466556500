/**
 * Resource that represents a Place.
 *
 * @name Place
 * @class
 * @augments TmrResource
 */
function PlaceFactory(TmrResource, tmrPlaceTypes, Hierarchy) {
  'ngInject'
  let referencedProperties = [
    // place.hierarchy property will be instantiated as a Hierarchy resource
    { property: 'hierarchy', resource: Hierarchy },
  ]

  let PlaceWithPropellant = TmrResource('PlaceWithPropellant', {}, referencedProperties)

  PlaceWithPropellant.getTypeDescription = getTypeDescription
  PlaceWithPropellant.prototype.$getTypeDescription = getTypeDescription

  return PlaceWithPropellant

  /**
   * Returns the Place type description.
   *
   * @method Place#getTypeDescription
   * @returns {string}
   */
  function getTypeDescription() {
    return tmrPlaceTypes[this.placeType]
  }
}

export const factory = ['PlaceWithPropellant', PlaceFactory]
