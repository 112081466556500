import { tmrResourceFormController } from 'tmr/controllers/resourceForm/resourceForm'
import templateUrl from './form.html'

/**
 * AutomaticTTZDestination add/edit form component.
 *
 * @type {ngComponent}
 */
export const tmrAdminAutomaticTTZDestinationForm = {
  ngType: 'component',
  templateUrl,
  controller: 'tmrAdminAutomaticTTZDestinationFormController',
  bindings: {
    resource: '<',
    toast: '<',
  },
}

/**
 * Controller of component {@link tmrAdminAutomaticTTZDestinationForm}.
 *
 * @type {ngController}
 */
export class tmrAdminAutomaticTTZDestinationFormController extends tmrResourceFormController {
  constructor(
    $scope,
    $state,
    $stateParams,
    TmrInvalidFormError,
    TmrToast,
    Scanner,
    Zone
  ) {
    'ngInject'
    super($scope, $state, $stateParams, TmrInvalidFormError, TmrToast)
    Object.assign(this, { Scanner, Zone })
  }

  getScanners(value) {
    return this.Scanner.search({ value }).$promise
  }

  getZones(value) {
    return this.Zone.search({ value }).$promise
  }
}
