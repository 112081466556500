/**
 * Resource that represents an GenericTank.
 *
 * @name GenericTank
 * @class
 * @augments TmrResource
 */
function GenericTankFactory(TmrResource, Product, Zone) {
  'ngInject'
  let referencedProperties = [
    // genericTank.product property will be instantiated as a Product resource
    { property: 'product', resource: Product },
    // genericTank.zone property will be instantiated as a Zone resource
    { property: 'zone', resource: Zone },
  ]

  let GenericTank = TmrResource('GenericTank', {}, referencedProperties)

  GenericTank.getByCode = getByCode
  GenericTank.prototype.$getProduct = $getProduct

  /**
   * Override default label
   *
   * @method GenericTank#getLabel
   */
  GenericTank.prototype.$getLabel = function () {
    return this.huCode
  }

  /**
   * Backwards-compatible identifiers property
   */
  Object.defineProperty(GenericTank.prototype, '$identifiers', {
    get: function () {
      if (!Array.isArray(this.itemIdentifiers))
        this.itemIdentifiers = [{ identifierType: 'UHFTag', code: this.epc }]
      if(this.itemIdentifiers.length === 0)
        this.itemIdentifiers = [{ identifierType: 'UHFTag', code: null }]
      return this.itemIdentifiers
    },
  })

  return GenericTank

  /**
   * @method  GenericTank#getByCode
   */
  function getByCode(code) {
    if (!code) {
      throw new Error(
        'GenericTank.getByCode(code) action requires a code (upc) parameter'
      )
    }
    return this.query({ code, limit: 1 }).then(result => result[0])
  }

  /**
   * Loads the product linked to this genericTank.
   *
   * @method  GenericTank#$getProduct
   */
  function $getProduct() {
    if (this.$product === undefined) {
      this.$product = this.upc ? Product.getByUpc(this.upc) : null
    }
    return this.$product
  }
}

export const factory = ['GenericTank', GenericTankFactory]
