/**
 * Resource that represents a Test.
 *
 * @name Test
 * @class
 * @augments TmrResource
 */
function TestFactory(TmrResource, HUItem) {
  'ngInject'
  let referencedProperties = [
    // test.huitem property will be instantiated as a Huitem resource
    { property: 'huitem', resource: HUItem },
  ]

  return TmrResource('Test', {}, referencedProperties)

}

export const factory = ['Test', TestFactory]
