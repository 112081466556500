import { resourceListBindings } from 'tmr/components/resourceList/resourceListHelper'
import templateUrl from './propellantPlace.html'

/**
 * Report Propellant in Zones route
 *
 * @namespace routes.ReportPropellantPlace
 * @type {TmrRoute}
 */
export const route = {
  name: 'report.propellantPlace.list',
  label: 'REPORT.PROPELLANT.TITLE',
  mainMenu: false,
  url: '/propellant/place',
  component: 'tmrReportPropellantPlace',
  weight: 3,
  resourceType: 'PlaceWithPropellant',
  accessControl: ['Resource.list', 'Resource.Propellant.list'],
  resourceList: {
    searchParameters: [
      'code',
      'description',
      'placeType',
      'hierarchy.id',
      'externalCode',
      'countryCode',
    ],
  },
  resolve: {
    placeTypes: /*@ngInject*/ tmrPlaceTypes => tmrPlaceTypes,
  },
}

/**
 * Component for {@link routes.tmrReportZones Report Zones route}
 *
 * @type {ngComponent}
 */
export const tmrReportPropellantPlace = {
  ngType: 'component',
  templateUrl,
  bindings: resourceListBindings({
    placeTypes: '<',
  }),
}
