import logo from '../../../images/new-logo-big.png'
import { tmrForgotPasswordController } from 'tmr/routes/forgotPassword/forgotPassword'


/**
 * Controller of component {@link tmrLoginController}
 *
 * @type {ngController}
 */
export class tmrForgotPasswordControllerOverride extends tmrForgotPasswordController {

  constructor(
    $state, TmrApiAuth, TmrToast
  ) {
    super($state, TmrApiAuth, TmrToast);
    Object.assign(this, {
      logo
    })
  }

}

const tmrForgotPasswordDirective = /*@ngInject*/ $delegate => {
  $delegate[0].controller = 'tmrForgotPasswordControllerOverride'
  return $delegate
}

export const decorator = [
  'tmrForgotPasswordDirective',
  tmrForgotPasswordDirective,
]
