import { constant as defaultPagingParams } from 'tmr/constants/defaultPagingParams'


export const constant = [

  defaultPagingParams[0],
  Object.assign(defaultPagingParams[1], {
    limit: 25
  }),
]
