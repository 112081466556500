import { constant as movementTypes } from 'tmr/constants/movementTypes'

export const constant = [
  'tmrMovementTypes',
  {
    ...movementTypes[1],
    SAPRealignmentDto: 'MOVEMENTTYPES.SAPREALIGNMENT',
    WasteDto: 'MOVEMENTTYPES.WASTE',
    SwitchBoxDto: 'MOVEMENTTYPES.SWITCHBOX',
    IncreaseDto: 'MOVEMENTTYPES.INCREASE',
    TestMovementDto: 'MOVEMENTTYPES.TEST',
    PropellantDto: 'MOVEMENTTYPES.PROPELLANT',
    DisassociateDto: 'MOVEMENTTYPES.DISASSOCIATE',
    RepartitionDto : 'MOVEMENTTYPES.REPARTITION',
    DischargeDto : 'MOVEMENTTYPES.DISCHARGE',
    EmptyDto : 'MOVEMENTTYPES.EMPTY'
  },

]
